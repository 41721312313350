import { FC } from "react";
import useStyles from "./styles";

const poolImage = "/images/pool_circle.jpg";

type Props = {
  poolDetailsMapping: any;
  poolDetails: any;
};

const HeaderByToken: FC<Props> = ({ poolDetailsMapping, poolDetails }) => {
  const styles = useStyles();
  // const [copiedAddress, setCopiedAddress] = useState(false);

  // var minTierDisplay = navHeader[0]?.minTier?.display;
  const minTierDisplay = poolDetailsMapping?.minTier?.display;
  const method = poolDetailsMapping?.method?.display;
  const {
    telegram_link,
    twitter_link,
    medium_link,
    discord_link,
    facebook_link,
    youtube_link,
  } = poolDetails?.socialNetworkSetting || {};

  return (
    <div className={styles.buyTokenHeader}>
      <div className={styles.tokenImg}>
        <img
          className={styles.iconToken}
          src={poolDetails?.banner || poolImage}
          alt=""
        />
      </div>
      <div className={styles.headerWrap}>
        <div>
          <div className={styles.title}>{poolDetails?.title}</div>
          <div className="token-symbol">
            {poolDetails?.tokenDetails.symbol}
          </div>
        </div>
        <div className={styles.poolHeaderInfo}>
          <div className={styles.flexRow}>
            <div className={styles.labelStatus + " " + styles.upperText}>
              {method ?? "PUBLIC"}
            </div>
            <div className={styles.labelStatus}>
              {minTierDisplay}
              &nbsp;
              {minTierDisplay !== "No tier & KYC required" &&
              minTierDisplay !== "No tier required"
                ? "Min Tier"
                : ""}
            </div>
          </div>
          <div className={styles.socialIcons}>
            {poolDetails?.website && (
              <a
                target="_blank"
                href={poolDetails?.website}
                className={styles.itemSocsial}
                rel="noreferrer"
              >
                <img src="/images/socials/website.svg" alt="" />
              </a>
            )}
            {telegram_link && (
              <a
                target="_blank"
                href={telegram_link}
                className={styles.itemSocsial}
                rel="noreferrer"
              >
                <img src="/images/socials/telegram.svg" alt="" />
              </a>
            )}
            {twitter_link && (
              <a
                target="_blank"
                href={twitter_link}
                className={styles.itemSocsial}
                rel="noreferrer"
              >
                <img src="/images/socials/twitter.svg" alt="" />
              </a>
            )}
            {medium_link && (
              <a
                target="_blank"
                href={medium_link}
                className={styles.itemSocsial}
                rel="noreferrer"
              >
                <img src="/images/socials/m.svg" alt="" />
              </a>
            )}
            {discord_link && (
              <a
                target="_blank"
                href={discord_link}
                className={styles.itemSocsial}
                rel="noreferrer"
              >
                <img src="/images/socials/discord.svg" alt="" />
              </a>
            )}
            {facebook_link && (
              <a
                target="_blank"
                href={facebook_link}
                className={styles.itemSocsial}
                rel="noreferrer"
              >
                <img src="/images/socials/facebook.svg" alt="" />
              </a>
            )}
            {youtube_link && (
              <a
                target="_blank"
                href={youtube_link}
                className={styles.itemSocsial}
                rel="noreferrer"
              >
                <img src="/images/socials/youtube.svg" alt="" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderByToken;

import { makeStyles } from "@material-ui/core";
import { Height } from "@terra-money/terra.js/dist/core/ibc/core/client/Height";
import { transform } from "lodash";

const useStyles = makeStyles((theme: any) => {
  const widthDetails = "calc(100vw / 5)",
    minWidthDetails = 260,
    maxWidthDetails = 300,
    widthBtnMobile = "calc((90vw - 48px) / 2)",
    widthProgress = "calc(100vw / 5 - 40px)",
    colorGreen = "#5EFF8B";

  return {
    borderGradien: {
      padding: "1px",
      background: "linear-gradient(90deg, #A5CCFF 0%, #E7F2FF 100%)",
      borderRadius: "20px !important",
      marginBottom: 6,
      overflow: "hidden",
    },
    pool: {
      background: "linear-gradient(235.66deg, #ECF5FF 29.71%, #FFFFFF 118.81%)",
      color: "#000B60",
      boxShadow: "unset",
      padding: "20px",
      borderRadius: "18px !important",
      [theme.breakpoints.down("sm")]: {
        padding: "16px",
        "& .MuiIconButton-edgeEnd": {
          // marginTop: "10px !important",
        },
      },
      "& .MuiAccordionSummary-expandIcon": {
        paddingTop: "0px",
        paddingBottom: "0px",
        height: 48,
        display: "flex",
        alignItems: "center",
      },
      "& .MuiIconButton-edgeEnd": {
        marginRight: 0,
      },

      "& .MuiAccordionSummary-root": {
        padding: 0,
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          alignItems: "flex-start",
        },

        "& .MuiAccordionSummary-content": {
          margin: 0,
        },
      },
      "& .Mui-expanded": {
        margin: "0",
        "& .pool--expand-text": {
          color: "#1F5CD0",
        },
        "& .MuiIconButton-label": {
          color: "#1F5CD0",
        },
      },

      "& .MuiIconButton-label": {
        color: "#1F5CD0",
      },
    },
    poolLogo: {
      height: "48px",
      width: "48px",
      borderRadius: "8px",
      marginRight: 8,
    },
    poolLine: {
      height: 1,
      marginTop: 9,
      background: "#000B60",
      opacity: 0.1,
    },
    expandText: {
      fontSize: 16,
      paddingBottom: "3px",
      display: "flex",
      width: "37px",
      alignItems: "center",
      textAlign: "right",
      marginLeft: "auto",
      font: "normal normal 500 14px/18px ",
      justifyContent: "end",
      "&.color-hide": {
        color: "#1F5CD0",
        fontWeight: 600,
      },
      "&.color-details": {
        color: "#1F5CD0",
        fontWeight: 600,
      },
      [theme.breakpoints.down("xs")]: {
        font: "normal normal 500 16px/24px ",
        alignItems: "center",
      },
    },
    poolDetails: {
      // margin: "15px 0px 0",
      padding: "20px 0 0",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        padding: "0 0",
        width: "100%",
      },
    },
    poolTitleWrap: {
      display: "flex",
      width: widthDetails,
      minWidth: minWidthDetails,
      maxWidth: maxWidthDetails,
      [theme.breakpoints.down("sm")]: {
        minWidth: "calc(100vw / 2)",
      },
    },
    poolHeaderDetails: {
      flex: 1,
      display: "Grid",
      gridTemplateColumns: "1fr 0.6fr 1.4fr 1fr 1.2fr",
      gap: "min(30px, calc(100vw / 35))",
      alignItems: "center",
      marginRight: 20,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    poolHeaderDetailsMobile: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        flex: 1,
        display: "Grid",
        width: "calc(100% + 32px)",
        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        gap: "min(30px, calc(100vw / 35))",
        alignItems: "center",
        marginTop: 16,
        paddingTop: 8,
        borderTop: "1px solid rgba(0, 11, 96, 0.1)",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        gap: 20,
        justifyContent: "space-between",
      },
    },
    poolHeaderPanel: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    poolHeaderPanelOut: {
      width: "100%",
    },
    poolHeaderButtonWrap: {
      display: "flex",
      justifyContent: "center",
    },
    poolDetailsContent: {
      width: widthDetails,
      minWidth: minWidthDetails,
      maxWidth: maxWidthDetails,
      display: "flex",
      flexDirection: "column",
      paddingRight: 20,
      // minHeight: "110px",
      [theme.breakpoints.down("xs")]: {
        padding: "16px 0",
        minWidth: "unset",
        maxWidth: "unset",
        width: "calc(90vw - 40px)",
      },
    },
    poolDetailsItem: {
      display: "Grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 8,
      marginTop: 6,
      "&:first-child": {
        marginTop: 0,
      },
      "&.pt10-mobile": {
        [theme.breakpoints.down("xs")]: {
          paddingTop: 10,
        },
      },
    },
    poolDetailsBlock: {
      display: "flex",
      flexDirection: "column",
      padding: "0 20px",
      borderLeft: "1px solid rgba(0, 11, 96, 0.1)",
      [theme.breakpoints.down("xs")]: {
        padding: "16px 0",
        width: "100% !important",
        border: 0,
        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
      },
    },
    poolDetailInfoMobile: {
      [theme.breakpoints.down("xs")]: {
        paddingBottom: 16,
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
      },
    },
    btn: {
      width: 100,
      // width: "100%",
      height: 28,
      borderRadius: 2,
      outline: "none",
      font: "normal normal 500 12px/16px ",
      border: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      cursor: "pointer",
      "&:hover": {
        opacity: 0.85,
      },
      "&:disabled": {
        opacity: 0.6,
        cursor: "not-allowed",
      },
      [theme.breakpoints.down("xs")]: {
        width: "auto",
      },
    },
    btnModal: {
      height: 36,
      width: "auto",
      font: "normal normal 500 14px/20px ",
    },
    btnClaimToken: {
      paddingBottom: "1px",
      color: "#fff",
      borderRadius: "60px",
      backgroundColor: "#000B60",
      fontSize: "12px",
      lineHeight: "14px",
    },
    btnStakeReward: {
      marginTop: 6,
      paddingBottom: "1px",
      color: "#000B60",
      borderRadius: "60px",
      backgroundColor: "transparent",
      border: "1px solid #000B60",
      fontSize: "12px",
      lineHeight: "14px",
    },
    btnStake: {
      paddingBottom: "1px",
      color: "#fff",
      borderRadius: "60px",
      backgroundColor: "#0058FF",
      fontSize: "12px",
      lineHeight: "14px",
    },
    btnUnstake: {
      marginTop: 4,
      paddingBottom: "1px",
      color: "#0058FF",
      borderRadius: "60px",
      backgroundColor: "transparent",
      border: "1px solid #0058FF",
      fontSize: "12px",
      lineHeight: "14px",
    },
    btnEnable: {
      paddingBottom: "2px",
      color: "#fff",
      borderRadius: "60px",
      backgroundColor: "#0058FF",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 600,
    },
    btnWithdraw: {
      marginTop: "4px",
      paddingBottom: "1px",
      color: "#fff",
      borderRadius: "60px",
      backgroundColor: "#0058FF",
      fontSize: "12px",
      lineHeight: "14px",
    },
    btnSwitch: {
      width: "auto",
      color: "#000",
      backgroundColor: colorGreen,
      padding: "0 20px",
      marginTop: "4px",
      gap: 6,
      borderRadius: "60px",
    },
    btnConnect: {
      color: "#fff",
      backgroundColor: "#0058FF",
      width: 120,
      marginTop: "4px",
      borderRadius: "60px",
      "& div": {
        paddingBottom: "2px",
      },
      [theme.breakpoints.down("xs")]: {
        width: widthBtnMobile,
        marginTop: 8,
      },
    },
    btnSelectPercent: {
      height: 32,
      width: "67px",
      margin: "auto 0px 10px",
      color: "#0058FF",
      backgroundColor: "#F1F6FF",
      borderRadius: "4px",
      border: 0,
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 600,
      outline: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      cursor: "pointer",
      "&:hover": {
        opacity: 0.85,
      },
      "&:disabled": {
        opacity: 0.6,
        cursor: "not-allowed",
      },
      [theme.breakpoints.down("xs")]: {
        width: "calc((100vw - 56px - 40px - 24px) / 4)",
      },
    },
    btnSwitchModal: {
      backgroundColor: colorGreen,
      color: "#000",
      "&:disabled": {
        backgroundColor: colorGreen,
      },
    },
    btnCancelModal: {
      backgroundColor: "#727272",
      color: "#fff",
    },
    btnStakeModal: {
      paddingBottom: "2px",
      color: "#fff",
      borderRadius: "60px",
      backgroundColor: "#0058FF",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeigth: 600,
    },
    btnGetPkfModal: {
      paddingBottom: "2px",
      color: "#0058FF",
      borderRadius: "60px",
      backgroundColor: "transparent",
      border: "1px solid #0058FF",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 600,
    },
    btnClaimModal: {
      backgroundColor: "#FFD058",
      color: "#000000",
      width: "100%",
    },
    groupButtonStaking: {
      display: "flex",
      flexDirection: "column",
      marginTop: "4px",
      paddingTop: 8,
      [theme.breakpoints.down("xs")]: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 8,
      },
    },
    groupWithdraw: {
      display: "flex",
      gap: "50px",
    },
    modalContent: {
      border: "none",
      backgroundColor: "#FFFFFF",
      width: "calc(100vw - 56px)",
      maxWidth: "360px",
      padding: "32px 0 0",
      borderRadius: "28px",
      color: "#FFFFFF",
      [theme.breakpoints.down("xs")]: {
        padding: "40px 0 0",
      },
    },
    modalHeader: {
      textAlign: "center",
      "& .title": {
        margin: "0 auto",
        fontSize: "32px",
        lineHeight: "32px",
        fontWeight: 600,
        color: "#000B60",
      },
      "& .btn-close": {
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
      },
    },
    modalBody: {
      padding: "0 40px",
      margin: " 2px 0 20px 0",
      overflowX: "hidden",
      [theme.breakpoints.down("xs")]: {
        padding: "0 20px",
      },

      "& .token-type": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "6px",
        font: "14px/24px ",
      },
      "& .token-type-title": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
      },
      "& .token-detail": {
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
        fontWeight: 600,
      },
      "& .token-min-stake": {
        color: "#0058FF",
      },
      "& .token-logo": {
        width: "22px",
        height: "22px",
        borderRadius: "28px",
        margin: "0 5px",
      },

      "& .input-group": {
        display: "flex",
        flex: 1,
        padding: "8px 12px",
        borderRadius: 8,
        border: "1px solid #F2F6FE",
        background: "#F2F6FE",

        "& input": {
          flex: 1,
          padding: 0,
          width: "auto",
          cursor: "auto",
          fontSize: "16px",
          lineHeight: "18px",
          color: "#000B60",
          background: "none",
          border: 0,
          outline: "none",
        },
        "& span": {
          display: "flex",
          alignItems: "center",
          fontSize: "16px",
          lineHeight: "18px",
          color: "#0058FF",
          fontWeight: 600,
        },
      },
      "& .token-balance": {
        margin: "0 0 0 auto",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#000B60",
        fontWeight: 400,
      },
      "& .subtitle": {
        display: "flex",
        marginBottom: 8,
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
        fontWeight: 600,
      },
    },
    modalFooter: {
      padding: "10px 40px 20px",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 8,
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
        padding: "20px 20px",
      },
    },
    modalFooterSingleBtn: {
      padding: "20px 40px",
      display: "grid",
      gridTemplateColumns: "1fr",
      gap: 8,
      [theme.breakpoints.down("xs")]: {
        padding: "20px 20px",
      },
    },

    earnedWrap: {
      display: "flex",
      flexDirection: "column",
      paddingRight: 20,
      maxWidth: 150,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        maxWidth: "unset",
        padding: "16px 0",
        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
      },
    },

    groupButton: {
      marginTop: "4px",
      display: "flex",
      flexDirection: "column",
      paddingTop: 8,
      [theme.breakpoints.down("xs")]: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 8,
      },
    },
    groupButtonPercent: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "8px",
    },

    progressArea: {
      marginBottom: 12,
      width: widthProgress,
      minWidth: minWidthDetails - 40,
      maxWidth: maxWidthDetails - 40,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        maxWidth: "unset",
      },
    },

    progress: {
      display: "block",
      width: "100%",
      height: 4,
      background: "#81A8F9",
      borderRadius: 20,
      marginTop: 4,
      marginBottom: 5,
    },

    iconCurrentProgress: {
      transform: "scale(0.8)",
      position: "absolute",
      width: 16,
      height: 16,
      borderRadius: 16,
      background: "#B073FF",
      top: -6,
      right: "-10px !important" as any,
    },

    currentProgress: {
      position: "relative",
      height: 4,
      background: "#1F5CD0",
      borderRadius: 20,
      display: "block",
      transition: "2s",

      "&.inactive": {
        width: "0 !important",
      },
    },
    modalProgress: {
      maxWidth: "unset",
      width: "100%",
      marginTop: 16,
    },
    currentPercentage: {
      display: "flex",
      "& div": {
        padding: "2px 12px 4px",
        font: "12px/14px ",
        background: "#0055D6",
        borderRadius: "20px",
        transitionDuration: "2000ms",
        cursor: "pointer",
      },
    },

    textPrimary: {
      color: "#000B60",
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 600,
    },
    textSecondary: {
      color: "#000B60",
      fontSize: "16px",
      lineHeight: "19px",
    },
    textAPR: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      lineHeight: "26px",
      fontWeight: 500,
      color: "#FFFFFF",
      "& img": {
        marginLeft: 4,
        width: 12,
        height: 12,
      },
    },
    textPoolTitle: {
      color: "#0058FF",
      fontSize: "24px",
      lineHeight: "24px",
      fontWeight: 600,
      maxWidth: "250px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        whiteSpace: "wrap",
        fontSize: "20px",
        lineHeight: "20px",
      },
    },
    textPoolSubTitle: {
      color: "#000B60",
      fontSize: "16px",
      lineHeight: "19px",
    },
    textDescription: {
      color: "#0058FF",
      fontSize: "18px",
      lineHeight: "21px",
      fontWeight: 600,
    },
    mt8Mobile: {
      [theme.breakpoints.down("sm")]: {
        marginTop: 8,
      },
    },
    mb8: {
      marginBottom: 8,
    },
    tokenInfo: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
        marginTop: 16,
        paddingTop: 16,
      },
    },
    tokenInfoItem: {
      width: "auto",
      marginTop: 4,
      [theme.breakpoints.down("sm")]: {
        marginTop: 8,
      },
      "&:first-child": {
        marginTop: 0,
      },
    },
    btnLink: {
      height: 28,
      width: "fit-content",
      padding: "6px 8px",
      backgroundColor: "#3B3B3B",
      border: "none",
      borderRadius: 6,
      color: "#FFFFFF",
      display: "flex",
      textAlign: "left",
      alignItems: "center",
      cursor: "pointer",
      font: "12px/16px ",
      "&:hover": {
        opacity: 0.8,
        color: "#FFFFFF",
      },
      "& img": {
        marginLeft: 6,
        width: 12,

        '&[data-role="metamask"]': {
          width: 14,
          height: 12,
        },
      },
    },
    progressStake: {
      width: "100%",
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      marginTop: 14,
      marginBottom: 6,
    },
    swapProgress: {
      display: "flex",
      flexDirection: "column",
      color: "#fff",
      paddingBottom: 16,
      width: "100%",
      "&. text-progress": {
        font: "12px/16px ",
      },
    },
    titleProgressArea: {
      font: "14px/24px ",
      color: "#AEAEAE",
    },

    progressBar: {
      // marginLeft: "auto",
      display: "block",
      width: "100%",
      height: 4,
      background: "rgba(129, 168, 249, 0.2)",
      borderRadius: 20,
      // maxWidth: 240,
      [theme.breakpoints.down("xs")]: {
        width: "calc(100%)",
        maxWidth: "calc(100%)",
      },
    },

    progressInfo: {
      display: "flex",
      flexDirection: "row",
      font: "12px/16px ",
      color: "#fff !important",
      alignItems: "center",
      justifyContent: "start",

      "& span": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "&:first-child": {
          padding: "4px 12px 6px",
          background: "#0055D6",
          borderRadius: 20,
          fontSize: "12px",
          lineHeight: "14px",
        },
        "&:last-child": { fontSize: "14px", lineHeight: "16px", color: "#fff" },
      },
    },
  };
});

export default useStyles;

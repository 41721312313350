import { makeStyles, Theme } from "@material-ui/core";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme: Theme) => {
  return {
    animation: {},
    container: {
      position: "relative",
      height: "100vh",
      "& >img": {
        width: "100%",
        height: "auto",
      },
      "& .main-content h1": {
        //textAlign: "center",
        color: "#FFFFFF",
        fontSize: 48,
        marginBottom: "12px",
      },

      "& .main-content .gradient-underline": {
        position: "relative",
      },
      "& .main-content .gradient-underline-text": {
        "&:after": {
          content: "''",
          position: "absolute",
          top: "90%",
          width: "100%",
          left: 0,
          zIndex: -1,
          height: 4,
          borderRadius: 2,
          background:
            "linear-gradient(275.44deg, #724CE8 0.33%, #C34C52 30.66%, #FEC8C8 58.66%, #4161BF 86.07%, #724CE8 112.31%)",
        },
      },
      "& .main-content h2": {
        //textAlign: "center",
        color: "#FFFFFF",
        marginBottom: "12px",
        maxWidth: "calc(100% - 30px)",
        margin: "auto",
      },
      "& .main-content": {
        margin: "auto",
        maxWidth: 1280,
        color: "#FFFFFF",
      },
      "& .main-content .title": {
        marginTop: 20,
        maxWidth: "40%",
      },
      "& .main-content .description": {
        maxWidth: "40%",
        color: "rgba(255, 255, 255, 0.8)",
      },
      "& .buttons": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",

        "& img": {
          width: "16px",
          height: "16px",
          objectFit: "contain",
        },
      },
      "& .buttons button": {
        minWidth: 150,
        height: 36,
        color: "#FFFFFF",
        border: "none",
        outline: "none",
        padding: "9px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 4,
        margin: "36px 6px 0 6px",
        cursor: "pointer",
        "&:hover": {
          opacity: 0.8,
        },
      },
      "& .buttons a:nth-child(2) button": {
        border: "1px solid white",
        background: "transparent",
      },
      "& .buttons a:nth-child(1) button": {
        backgroundColor: "#D01F36",
      },
      "& > div": {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
        width: "100%",
        textAlign: "left",
      },
      [theme.breakpoints.down("sm")]: {
        overflow: "hidden",
        "& > div": {
          top: "60%",
          transform: "translate(-50%, -60%)",
        },
        "& >img": {
          objectFit: "cover",
          // height: "600px",
        },
        "& .main-content h1": {
          margin: "15px auto",
        },
      },
      [theme.breakpoints.down("xs")]: {
        height: "100vh",
        overflow: "hidden",
        "& img": {
          objectFit: "unset",
          height: "auto",
          width: "100%",
        },
        "& img.bg2": {
          objectFit: "cover",
          height: "500px",
          width: "100%",
        },
        "& .main-content h1": {
          display: "block",
          width: "100%",
          margin: "20px auto",
          fontSize: 34,
          lineHeight: "40px",
        },
        "& p": {
          fontSize: "16px",
        },
        "& .buttons": {
          marginTop: "40px",
          flexDirection: "column",
          "& button": {
            width: "280px",
            margin: "15px auto 0",
            padding: "0 15px",
          },
          "& button:first-child": {
            margin: "20px auto 0",
          },
        },
        "& .description": {
          padding: "0 5%",
          fontSize: 14,
          lineHeight: "24px",
          "& br": {
            display: "none",
          },
        },
      },
    },
    btnGradient: {
      marginTop: 20,
      height: 36,
      width: "fit-content",
      minWidth: 150,
      color: "white",
      cursor: "pointer",
      display: "inline-block",
      backgroundClip: "padding-box",
      border: " solid 2px transparent",
      position: "relative",
      background: "linear-gradient(25deg, #6254DA, #E59396)",
      "& a": {
        background: "#09090E",
        top: 2,
        right: 2,
        bottom: 2,
        left: 2,
        display: "flex",
        position: "absolute",
      },
    },
    btnContent: {
      display: "flex",
      margin: "auto",
      color: "white",
      paddingTop: 2,
    },
    btnPrimary: {
      "&:hover": {
        color: "white",
      },
      margin: 20,
      height: 36,
      width: "fit-content",
      minWidth: 150,
      color: "white",
      padding: 10,
      position: "relative",
      background: "transparent",
      border: "none",
      cursor: "pointer",
      display: "inline-block",
      "& img": {
        width: 16,
        height: 16,
      },
      "&:before, &:after": {
        position: "absolute",
        width: 10,
        height: "0.095rem",
        content: "''",
      },
      "&:before": {
        top: 0,
        left: -3,
        transform: "rotate(-45deg)",
        transformOrigin: "top right",
        background: "linear-gradient(to right, #764CE2 0%, #7E4CD2 100%)",
      },
      "&:after": {
        bottom: 0,
        right: -3,
        transform: "rotate(-45deg)",
        transformOrigin: "bottom left",
        background: "linear-gradient(to right, #D9B4C7 0%, #BEA5C5 100%)",
      },
    },
    btnPrimaryLine1: {
      display: "flex",
      "&:before, &:after": {
        position: "absolute",
        width: "calc(100% - 8px)",
        height: "0.18rem",
        content: "''",
      },
      "&:before": {
        top: 0,
        left: 7,
        height: "0.13rem",
        background: "linear-gradient(to right, #7E4CD2 0%, #E59396 100%)",
      },
      "&:after": {
        bottom: 0,
        right: 7,
        height: "0.095rem",
        background: "linear-gradient(to right, #6154DA 0%, #BBA4C5 100%)",
      },
    },

    btnPrimaryLine2: {
      display: "flex",
      margin: "auto",
      paddingTop: 2,
      "&:before, &:after": {
        position: "absolute",
        width: "0.095rem",
        content: "''",
      },
      "&:before": {
        bottom: 0,
        left: 0,
        height: "calc(100% - 7px)",
        background: "linear-gradient(to bottom, #754CE3 0%, #6154DA 100%)",
      },
      "&:after": {
        top: 0,
        right: 0,
        height: "calc(100% - 7px)",
        background: "linear-gradient(to bottom, #E79799 0%, #DCB6C7 100%)",
      },
    },

    mainContent: {
      "& h2": {
        padding: "0 32px",
        textAlign: "center",
        color: "#FFFFFF",
        marginBottom: "8px",
        minHeight: "64px",
      },

      "& p": {
        padding: "0 12px",
        textAlign: "center",
        color: "rgba(255, 255, 255, 0.6)",
      },
    },
    bannerRebrand: {
      width: "100%",
      maxWidth: "min(80%, 980px)",
      margin: "0 auto 20px",
      position: "absolute",
      transform: "translate(-50%, 0)",
      top: 80,
      left: "50%",
      "& img": {
        width: "100%",
        objectFit: "contain",
        borderRadius: 6,
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "calc(100% - 56px)",
      },
    },
    supportedExchanges: {
      maxWidth: "50%",
      width: "fit-content",
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      border: "1px solid #353539",
      boxShadow:
        "rgba(255, 255, 255, 0.05) 0px 6px 24px 0px, rgba(255, 255, 255, 0.08) 0px 0px 0px 1px",
      marginTop: 56,
      "& p": {
        font: "normal normal 500 16px/20px ",
      },
      [theme?.breakpoints?.down(720)]: {
        padding: "0 120px",
      },
      [theme?.breakpoints?.down("xs")]: {
        padding: "0 40px",
      },
    },

    about: {
      marginTop: 20,
      display: "flex",
      width: "100%",
      justifyContent: "start",

      "& .about-item": {
        display: "flex",
        flexDirection: "column",
        marginRight: 20,
        paddingRight: 20,
        //alignItems: "center",
        borderRight: "1px solid #353539",
        "&:last-child": {
          margin: 0,
          borderRight: "none",
        },
        "& img": {
          width: 48,
          marginRight: 12,
        },
        "& .data-number": {
          font: "normal normal 500 20px/24px ",
          color: "#fff",
        },
        "& .data-name": {
          font: "12px/16px ",
          color: "#fff",
          opacity: "0.8",
        },

        [theme.breakpoints.down("sm")]: {
          marginRight: 40,
        },
      },
    },
    cardContainer: {
      maxWidth: "1320px",
      margin: "0 auto",
      padding: "120px 20px 0",
      [theme.breakpoints.down("sm")]: {
        padding: "50px 20px 0",
      },
    },
    cardStakeTitle: {
      justifyContent: "center",

      [theme.breakpoints.down("sm")]: {
        display: "grid",
        gap: "8px",
      },
    },
    cardTitle: {
      fontSize: "48px",
      lineHeight: "48px",
      fontWeight: 500,
      color: "#0055D6",
    },
    cardSubTitle: {
      maxWidth: "630px",
      fontSize: "20px",
      lineHeight: "24px",
      color: "#000B60",
      [theme.breakpoints.only("xs")]: {
        textAlign: "center",
      },
    },
    btnStakeDiv: {
      display: "flex",
      paddingTop: "32px",
      justifyContent: "center",
      [theme.breakpoints.only("md")]: {
        paddingTop: "20px",
      },
      [theme.breakpoints.only("xs")]: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
      },
    },
    btnStakeNow: {
      height: 50,
      padding: "16px 48px 18px",
      background: "#0058FF",
      border: "none",
      borderRadius: "45px",
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: 600,
      color: "#FFFFFF",
      [theme.breakpoints.down("md")]: {
        padding: "10px 30px 12px",
      },
      [theme.breakpoints.only("xs")]: {
        width: "256px",
        height: 44,
      },
    },
    stakingHeader: {},
    btnHeader: {
      margin: "28px auto 0",
    },
    projectMain: {
      display: "flex",
      flexWrap: "wrap",
      width: "90%",
      margin: "0 auto",
      color: "white",
    },
    projectContainer: {
      width: isMobile ? "100%" : "50%",
      padding: "20px 20px",
      display: "inline-block",
      textAlign: "center",
      minHeight: "100%",
    },
    projectDetail: {
      borderRadius: 20,
      padding: 40,
      minHeight: "100%",
      background: isMobile ? "linear-gradient(#1e225d, #030925)" : "#020618",
      "& img": {
        marginTop: 20,
      },
      "& h1": {
        fontSize: isMobile ? 20 : 24,
      },
      "& h3": {
        color: "#ffffff90",
        fontSize: isMobile ? 14 : 16,
      },
      lineHeight: 2,
    },
    getAlert: {
      position: "relative",
      backgroundImage: "url(/images/bg_launch.png)",
      // backgroundSize: 'cover',
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },

    contentGetAlert: {
      width: 1080,
      margin: "auto",
      maxWidth: "calc(100vw - 80px)",
      minHeight: 224,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
    },
    mainEvent: {
      margin: "80px max(100px, 50% - 480px)",
      "& img": {
        width: "auto",
        [theme.breakpoints.down("md")]: {
          maxWidth: "calc(100% - 360px)",
        },
      },
    },

    titleGetAlert: {
      font: "normal normal bold 32px/36px ",
      textAlign: "center",
      marginBottom: 12,
      [theme.breakpoints.only("xs")]: {
        fontSize: 28,
        lineHeight: "32px",
      },
    },

    desGetAlert: {
      fontFamily: "",
      fontSize: 16,
      lineHeight: "24px",
      marginBottom: 40,
    },

    btnGetAlert: {
      minWidth: 170,
      minHeight: 36,
      maxWidth: "100%",
      background: "#000000",
      borderRadius: 4,
      border: "none",
      font: "normal normal bold 14px/18px ",
      color: "#FFFFFF",
      cursor: "pointer",
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      // [theme.breakpoints.down('sm')]: {
      //   width: 280,
      // },
      "&:hover": {
        opacity: 0.8,
      },
      "&:focus": {
        outline: "none",
      },

      "& img": {
        width: 12,
        marginRight: 6,
      },
    },

    [theme.breakpoints.down("md")]: {
      cardStakeTitle: {
        justifyContent: "center",
      },
      cardTitle: {
        fontSize: 40,
        lineHeight: "40px",
        fontWeight: 600,
        textAlign: "center",
      },
      cardSubTitle: {
        maxWidth: "100%",
      },
    },

    [theme.breakpoints.only("sm")]: {
      cardTitle: {
        fontSize: 30,
        lineHeight: "30px",
        fontWeight: 600,
        textAlign: "center",
      },
    },
    // mobile
    [theme.breakpoints.only("xs")]: {
      cardTitle: {
        fontSize: 30,
        lineHeight: "30px",
        fontWeight: 600,
        textAlign: "center",
      },
      cardSubTitle: {
        fontSize: 20,
        lineHeight: "16px",
      },
      about: {
        width: "100%",
        padding: "0 28px",
        justifyContent: "space-between",
        "& .about-item": {
          marginRight: 0,
        },
        "& .data-number": {
          font: "normal normal 500 16px/24px  !important" as any,
        },
      },
    },
    borderComponent: {
      position: "absolute",
      "&:before, &:after": {
        position: "absolute",
        width: 15,
        height: "0.095rem",
        content: "''",
      },
      "&:before": {
        top: 0,
        left: -4,
        transform: "rotate(-45deg)",
        transformOrigin: "top right",
        background: "#353539",
      },
      "&.top-left": {
        left: 0,
        top: 0,
      },
      "&.top-right": {
        top: 0,
        transform: "scaleX(-1)",
        right: 0,
      },
      "&.bottom-left": {
        bottom: 0,
        transform: "scaleY(-1)",
        left: 0,
      },
      "&.bottom-right": {
        bottom: 0,
        rotate: "-180deg",
        right: 0,
      },
    },
    borderComponentL1: {
      "&:before, &:after": {
        position: "absolute",
        content: "''",
      },
      "&:before": {
        top: 11,
        left: 0,
        width: "0.095rem",
        height: 30,
        background: "#353539",
      },
      "&:after": {
        top: 0,
        left: 11,
        height: "0.095rem",
        width: 60,
        background: "#353539",
      },
    },
    instructionContainer: {
      width: "25%",
      "& img": {
        width: "90%",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: 410,
        width: "100%",
        padding: "20px 0px",
        "& .instruction-img": {
          width: "auto",
          margin: "20px auto",
          maxHeight: 185,
        },
      },
    },
    btn: {
      position: "absolute",
      bottom: 20,
      height: 36,
      width: "calc(100% - 20px)",
      font: "normal normal 500 14px/18px ",
      color: "rgba(239, 239, 229, 0.6)",
      border: "1px solid #353539",
      outline: "none",
      padding: "0 20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 1,
      backgroundColor: "transparent",
      cursor: "pointer",
      "& img": {
        height: 10,
        width: 16,
        opacity: 0.6,
      },
      "&:hover": {
        color: "rgba(239, 239, 229, 0.8)",
        opacity: 1,
      },
    },
    instructionContent: {
      height: `100%`,
      padding: 10,
      paddingBottom: 70,
      position: "relative",
    },
    instructionText: {
      "& h1": {
        fontSize: "24px",
        lineHeight: "36px",
      },
      "& p": {
        fontSize: "12px",
        lineHeight: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: 96,
      },
    },
    sliderContainer: {
      width: "100%",
      "& .slick-dots": {
        bottom: "-35px !important",
      },
    },
  };
});

export default useStyles;

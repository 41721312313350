import TierBenefits from "../../../AccountV2/MyTiers/TierBenefits";
import useStyles from "./style";

const TierSystem = () => {
  const styles = useStyles();

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <h2>RWA Tiering System</h2>
        <p>
          RWA employs a tiering system designed to reward users who participate
          more in our ecosystem.Higher tiers will increase your IDO allocations,
          provide exclusive access to pre launch projects and more.{" "}
          <a href="https://www.rwa.inc/" target="_blank" rel="noreferrer">
            Learn More
          </a>
        </p>
      </div>
      <div className={styles.tierTable}>
        <TierBenefits />
      </div>
    </div>
  );
};

export default TierSystem;

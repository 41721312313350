import { WalletConnect } from "@web3-react/walletconnect-v2";
import { initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { APP_NETWORKS_NAME, MAINNET_CHAINS, DEFAULT_CHAIN_ID } from "./network";
import { BscWallet } from "../connectors/BinanceWallet";

const envLocal = localStorage?.getItem("env");
const env = envLocal ? JSON?.parse(envLocal) : {};

const METAMASK_DEEPLINK = env.REACT_APP_METAMASK_DEEPLINK;

// mainnet only
const mainnet = Number(DEFAULT_CHAIN_ID);
const optionalChains = Object.keys(MAINNET_CHAINS)
  .map(Number)
  .filter((id) => id !== mainnet);

export const [metaMask, metaMaskHooks] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions })
);
// export const [bscWallet, bscWalletHooks] = initializeConnector<BscWallet>((actions) => new BscWallet({ actions }));
export const [walletConnect, walletConnectHooks] =
  initializeConnector<WalletConnect>(
    (actions) =>
      new WalletConnect({
        actions,
        options: {
          projectId: env.REACT_APP_WALLET_CONNECT_PROJECT_ID || "",
          showQrModal: true,
          rpcMap: Object.keys(MAINNET_CHAINS).reduce((prev, curr) => {
            return { ...prev, [curr]: MAINNET_CHAINS[curr]?.urls?.[0] || "" };
          }, {}),
          chains: [mainnet],
          optionalChains: [mainnet, ...optionalChains],
          optionalMethods: [
            "eth_signTypedData",
            "eth_signTypedData_v4",
            "eth_sign",
          ],
          qrModalOptions: {
            // https://walletconnect.com/explorer
            explorerRecommendedWalletIds: [
              "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // metamask
              "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0", // trust
              "c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a", // uniswap
              "f2436c67184f158d1beda5df53298ee84abfc367581e4505134b5bcf5f46697d", // crypto.com
              "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4", // binance defi
              "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa", // coinbase
              "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709", // okx
            ],
          },
        },
      })
  );

export interface WalletInfo {
  connector: MetaMask | WalletConnect | BscWallet;
  name: string;
  // iconName: string
  description: string;
  href: string | null;
  // color: string
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
  disableIcon: string;
  icon: string;
  deepLink?: string;
}

export enum ConnectorNames {
  MetaMask = "MetaMask",
  WalletConnect = "WalletConnect",
  WalletConnectBsc = "WalletConnectBsc",
  WalletConnectPolygon = "WalletConnectPolygon",
}

export type connectorNames = Extract<
  ConnectorNames,
  ConnectorNames.MetaMask | ConnectorNames.WalletConnect
>;

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    connector: metaMask,
    name: ConnectorNames.MetaMask,
    icon: "/images/metamask.svg",
    disableIcon: "/images/metamask-disabled.svg",
    description: "Easy-to-use browser extension.",
    href: null,
    mobile: true,
    deepLink: METAMASK_DEEPLINK,
  },
  // WALLET_CONNECT: {
  //   connector: walletConnect,
  //   name: ConnectorNames.WalletConnect,
  //   icon: "/images/WalletConnect.svg",
  //   description: "Connect to Trust Wallet, Rainbow Wallet and more...",
  //   disableIcon: "/images/wallet-connect-disabled.svg",
  //   href: null,
  //   mobile: true,
  // },
};

export const SUPPORTED_WALLETS_BSC: { [key: string]: WalletInfo } = {
  METAMASK: SUPPORTED_WALLETS.METAMASK,
  BSC_WALLET: SUPPORTED_WALLETS.BSC_WALLET,
  // WALLET_CONNECT: {
  //   connector: walletConnect,
  //   name: ConnectorNames.WalletConnect,
  //   icon: "/images/WalletConnect.svg",
  //   description: "Connect to Trust Wallet, Rainbow Wallet and more...",
  //   disableIcon: "/images/wallet-connect-disabled.svg",
  //   mobile: true,
  //   href: null,
  // },
};

export const SUPPORTED_WALLETS_BASE: { [key: string]: WalletInfo } = {
  METAMASK: SUPPORTED_WALLETS.METAMASK,
  WALLET_CONNECT: {
    connector: walletConnect,
    name: ConnectorNames.WalletConnect,
    icon: "/images/WalletConnect.svg",
    description: "Connect to Trust Wallet, Rainbow Wallet and more...",
    disableIcon: "/images/wallet-connect-disabled.svg",
    mobile: true,
    href: null,
  },
};

export const SUPPORTED_WALLETS_POLYGON: { [key: string]: WalletInfo } = {
  METAMASK: SUPPORTED_WALLETS.METAMASK,
  // WALLET_CONNECT: {
  //   connector: walletConnect,
  //   name: ConnectorNames.WalletConnect,
  //   icon: "/images/WalletConnect.svg",
  //   mobile: true,
  //   description: "Connect to Trust Wallet, Rainbow Wallet and more...",
  //   disableIcon: "/images/wallet-connect-disabled.svg",
  //   href: null,
  // },
};

export const SUPPORTED_WALLETS_AVALANCHE: { [key: string]: WalletInfo } = {
  METAMASK: SUPPORTED_WALLETS.METAMASK,
  // WALLET_CONNECT: {
  //   connector: walletConnect,
  //   name: ConnectorNames.WalletConnect,
  //   icon: "/images/WalletConnect.svg",
  //   description: "Connect to Trust Wallet, Rainbow Wallet and more...",
  //   disableIcon: "/images/wallet-connect-disabled.svg",
  //   href: null,
  //   mobile: true,
  // },
};

export const SUPPORTED_WALLETS_ARBITRUM: { [key: string]: WalletInfo } = {
  METAMASK: SUPPORTED_WALLETS.METAMASK,
};

export const connectorsByName: {
  [key in ConnectorNames]: MetaMask | WalletConnect;
} = {
  [ConnectorNames.MetaMask]: metaMask,
  [ConnectorNames.WalletConnect]: walletConnect,
  [ConnectorNames.WalletConnectBsc]: walletConnect,
  [ConnectorNames.WalletConnectPolygon]: walletConnect,
};

export const connectorsSupportByNetwork: {
  [key: string]: { [key: string]: WalletInfo };
} = {
  [APP_NETWORKS_NAME.METAMASK]: SUPPORTED_WALLETS,
  [APP_NETWORKS_NAME.BSC]: SUPPORTED_WALLETS_BSC,
  [APP_NETWORKS_NAME.BASE]: SUPPORTED_WALLETS_BASE,
  [APP_NETWORKS_NAME.POLYGON]: SUPPORTED_WALLETS_POLYGON,
  [APP_NETWORKS_NAME.AVALANCHE]: SUPPORTED_WALLETS_AVALANCHE,
  [APP_NETWORKS_NAME.ARBITRUM]: SUPPORTED_WALLETS_ARBITRUM,
};

import { useState, useCallback } from "react";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";

import { TokenType } from "../../../hooks/useTokenDetails";
import { getContractReadInstance } from "../../../services/web3";
import Pool_ABI from "../../../abi/PreSalePool.json";

const envLocal = localStorage?.getItem("env");
const env = envLocal ? JSON?.parse(envLocal) : {};

const useUserRefundToken = (
  tokenDetails: TokenType | undefined,
  poolAddress: string | undefined,
  networkAvaiable: string | "eth",
  currency: string | "eth"
) => {
  const [loadingRefundBalance, setLoadingRefundBalance] =
    useState<boolean>(false);
  const [refundBalance, setRefundBalance] = useState<string>();

  const retrieveRefundToken = useCallback(
    async (userAddress: string) => {
      try {
        if (
          userAddress &&
          poolAddress &&
          tokenDetails &&
          ethers.utils.isAddress(userAddress) &&
          ethers.utils.isAddress(poolAddress)
        ) {
          setLoadingRefundBalance(true);
          const contract = getContractReadInstance(
            Pool_ABI,
            poolAddress,
            networkAvaiable
          );
          if (contract) {
            const currencyInfo: any = {
              eth: {
                usdt: {
                  address: env.REACT_APP_USDT_SMART_CONTRACT,
                  decimal: 6,
                },
                usdc: {
                  address: env.REACT_APP_USDC_SMART_CONTRACT,
                  decimal: 6,
                },
              },
              bsc: {
                usdt: {
                  address: env.REACT_APP_USDT_BSC_SMART_CONTRACT,
                  decimal: 18,
                },
                busd: {
                  address: env.REACT_APP_BUSD_BSC_SMART_CONTRACT,
                  decimal: 18,
                },
                usdc: {
                  address: env.REACT_APP_USDC_BSC_SMART_CONTRACT,
                  decimal: 18,
                },
              },
              base: {
                usdc: {
                  address: env.REACT_APP_USDC_BASE_SMART_CONTRACT,
                  decimal: 6,
                },
              },
              polygon: {
                usdt: {
                  address: env.REACT_APP_USDT_POLYGON_SMART_CONTRACT,
                  decimal: 6,
                },
                usdc: {
                  address: env.REACT_APP_USDC_POLYGON_SMART_CONTRACT,
                  decimal: 6,
                },
              },
              avalanche: {
                usdt: {
                  address: env.REACT_APP_USDT_AVALANCHE_SMART_CONTRACT,
                  decimal: 6,
                },
              },
              arbitrum: {
                usdt: {
                  address: env.REACT_APP_USDT_ARBITRUM_SMART_CONTRACT,
                  decimal: 6,
                },
              },
            };

            const currencyAddress =
              currency == "eth"
                ? "0x0000000000000000000000000000000000000000"
                : currencyInfo[networkAvaiable][currency].address;
            const currencyDecimal =
              currency == "eth"
                ? 18
                : currencyInfo[networkAvaiable][currency].decimal;

            // const [userRefund, offeredCurrency] = await Promise.all([
            //   contract.methods.userRefundToken(userAddress).call(),
            //   contract.methods.offeredCurrencies(currencyAddress).call(),
            // ]);
            const userRefund = await contract.methods
              .userRefundToken(userAddress, currencyAddress)
              .call();

            let refundBalance = new BigNumber(userRefund.currencyAmount)
              .div(new BigNumber(10).pow(currencyDecimal))
              .toFixed();

            setRefundBalance(refundBalance);
            setLoadingRefundBalance(false);
            return {
              isClaimed: userRefund.isClaimed,
              currencyAmount: userRefund.currencyAmount,
              currency: userRefund.currency,
              balanceAmount: refundBalance,
            };
          }
          return {
            isClaimed: false,
            currencyAmount: null,
            currency: null,
          };
        }
      } catch (err) {
        console.error(err);
      }
    },
    [tokenDetails, poolAddress, networkAvaiable]
  );

  return {
    loadingRefundBalance,
    refundBalance,
    retrieveRefundToken,
  };
};

export default useUserRefundToken;

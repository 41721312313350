import { Link } from "react-router-dom";
import useStyles from "./style";
//@ts-ignore
import { Fade } from "react-reveal";
import useFetch from "../../../../hooks/useFetch";
import { useMemo, useState } from "react";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { Button, MenuItem, Popover } from "@material-ui/core";
import { StyledMenu } from "../../../../components/Base/HeaderDefaultLayout/HeaderDefaultLayout";

const Welcome = (props: any) => {
  const styles = useStyles();
  const { data } = useFetch<any>(`/statistic`);

  const raise = useMemo(() => {
    const numb = +data?.fund_raised || 0;
    if (numb > 1000000000) {
      return Math.floor(numb / 1000000000) + "B+";
    }
    if (numb > 1000000) {
      return Math.floor(numb / 1000000) + "M+";
    }
    if (numb > 1000) {
      return Math.floor(numb / 1000) + "K+";
    }
    return numb;
  }, [data]);

  const STAY_INFORM_LINKS = [
    {
      icon: "/images/landing/telegram.svg",
      name: "Telegram channel",
      url: "https://t.me/RWA_Inc",
    },
    {
      icon: "/images/landing/twitter.svg",
      name: "Follow us on X",
      url: "https://x.com/rwa_inc_",
    },
    {
      icon: "/images/landing/email.svg",
      name: "Join our newsletter",
      url: "mailto:support@rwa.inc",
    },
  ];

  const projectTypes = [
    "Real Estate",
    "Commodities",
    "Tokenized Equity",
    "and many more...",
  ];
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <img
          src="/images/landing/hero_img.png"
          className={styles.heroImg}
          alt="hero"
        />
        <div className={styles.filter}>
          <div className={styles.title}>
            <div className={styles.titleHead}>
              <Fade left>
                <h1>
                  <div>
                    Your destination for finding real world asset (RWA) token
                    launches.
                  </div>
                </h1>
              </Fade>
              <div className="btn-grid">
                <Link to="/pools">
                  <button className={styles.btnApply}>
                    Explore Project Launches
                  </button>
                </Link>
                <Link to="/staking-pools">
                  <button className={styles.btnDoc}>Stake $RWA</button>
                </Link>
                <>
                  <button
                    className={styles.btnInform}
                    aria-describedby={id}
                    onClick={handleClick}
                  >
                    Stay Informed
                    <ArrowDropDown />
                  </button>
                </>
                <StyledMenu
                  id="learn-menu"
                  MenuListProps={{
                    "aria-labelledby": "learn-menu-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  className={styles.learnMenu}
                  onClose={handleClose}
                >
                  {STAY_INFORM_LINKS.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        href={item.url}
                        target={"_blank"}
                        onClick={() => handleClose()}
                        disableRipple
                      >
                        <a
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            color: "#000B60",
                          }}
                          href={item?.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div
                            style={{
                              height: 24,
                              width: 24,
                              borderRadius: "50%",
                              backgroundColor: !!item.icon
                                ? "#DFF0FD"
                                : "transparent",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {!!item.icon && (
                              <img
                                src={item?.icon}
                                width={12}
                                height={12}
                                alt=""
                              ></img>
                            )}
                          </div>
                          {item.name}
                        </a>
                      </MenuItem>
                    );
                  })}
                </StyledMenu>
              </div>
            </div>
            <div className={styles.line} />
            <div className={styles.titleNumber}>
              <div>
                <h6>Project types</h6>
                <ul>
                  {projectTypes.map((projectType) => (
                    <p>{projectType}</p>
                  ))}
                </ul>
              </div>
              <div>
                <h6>{`+${+data?.number_project || 0} Projects`}</h6>
                <p>In pipeline</p>
              </div>
              <div>
                <h6>+40</h6>
                <p>Partners</p>
              </div>
            </div>
          </div>
          <div className={styles.contentBot}>
            <div className={styles.contentBotSlide}>
              <div className={styles.slide}>
                <div className={styles.slideChild}>
                  Welcome to RWA, a cutting-edge, trusted, and secure platform
                  for Web3 builders and investors. The RWA Launchpad is a
                  decentralized fundraising platform for a wide variety of
                  tokenized real world assets spanning multiple asset classes,
                  deal sizes and geographies.
                </div>
              </div>
              <div className={styles.filterSkew} />
            </div>
            <div className={styles.contentBotSupport}>
              <p>Supported by: </p>
              <div className="launchpad-support">
                <a
                  href="https://app.uniswap.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>
                    <img
                      src="/images/landing/uniswap.svg"
                      width={20}
                      height={20}
                      alt="uniswap"
                    />
                    <span>Uniswap</span>
                  </div>
                </a>
                <a href="https://www.gate.io" target="_blank" rel="noreferrer">
                  <div>
                    <img
                      src="/images/landing/gate.svg"
                      width={20}
                      height={20}
                      alt="gate.io"
                    />
                    <span>Gate.io</span>
                  </div>
                </a>
                <a
                  href="https://www.kucoin.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>
                    <img
                      src="/images/landing/kucoin.svg"
                      width={20}
                      height={20}
                      alt="kucoin"
                    />
                    <span>Kucoin</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;

import withWidth from "@material-ui/core/withWidth";
import { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
// import ApplyToLaunch from "../../components/Base/ApplyToLaunch";
import { POOL_IS_PRIVATE } from "../../constants";
import useFetch from "../../hooks/useFetch";
import Card from "./Card";
import useStyles from "./style";
import Welcome from "./LandingComponents/Welcome";
import Staking from "./LandingComponents/Staking";
import LaunchWithUs from "./LandingComponents/LaunchWithUs";
import Partners from "./LandingComponents/partners";
import UserProcess from "./UserProcess";
import StreamLined from "./streamlined";
import CardActive from "./CardActive";
import useMediaQuery from "../../hooks/useMediaQuery";
import TierSystem from "./LandingComponents/TierSystem";

type PoolData = {
  data: [];
  total: string;
  perPage: number;
  page: number;
  lastPage: number;
};

const Dashboard = (props: any) => {
  const styles = useStyles();
  const isTablet = useMediaQuery("(min-width: 960px)");

  const [upcomingPoolsV3Display, setUpcomingPoolsV3Display] = useState<
    Array<any>
  >([]);
  const [activePoolsV3Display, setActivePoolsV3Display] = useState<Array<any>>(
    []
  );

  const { data: upcomingTBAPoolsV3, loading: loadingUpcomingTBAPoolV3 } =
    useFetch<PoolData>(`/upcoming-tba-pools?limit=99&page=1`);
  const {
    data: upcomingPoolsV3,
    loading: loadingUpcomingPoolV3,
    refetchData: refetchUpcoming,
  } = useFetch<PoolData>(`/pools/v3/upcoming-pools?limit=4`);
  const {
    data: activePoolsV4,
    loading: loadingActivePoolsV4,
    refetchData: refetchActive,
  } = useFetch<PoolData>(`/pools/v4/active-pools?limit=4`);

  const setStatusPools = async (pools: Array<any>) => {
    await Promise.all(
      pools.map(async (pool: any) => {
        pool.status = pool.campaign_status;
      })
    );
  };

  const refetchData = () => {
    refetchUpcoming();
    refetchActive();
  };

  useEffect(() => {
    if (!upcomingPoolsV3 || !upcomingTBAPoolsV3) return;
    let tbaPools =
      upcomingTBAPoolsV3?.data && upcomingTBAPoolsV3.data.length > 0
        ? [...upcomingTBAPoolsV3.data].map((pool: any) => {
            return {
              ...pool,
              is_private: +pool.is_private,
              campaign_status: "Upcoming",
              isTBAPool: true,
            };
          })
        : [];
    if (upcomingPoolsV3?.data) {
      let pools = [...upcomingPoolsV3.data, ...tbaPools];
      setStatusPools(pools).then(() => {
        setUpcomingPoolsV3Display(
          pools.filter((p: any) => p?.is_private !== POOL_IS_PRIVATE.COMMUNITY)
        );
      });
    }
  }, [
    loadingUpcomingPoolV3,
    upcomingPoolsV3,
    upcomingTBAPoolsV3,
    loadingUpcomingTBAPoolV3,
  ]);

  useEffect(() => {
    if (!activePoolsV4 || !loadingActivePoolsV4) return;
    if (activePoolsV4?.data && activePoolsV4.data.length) {
      let pools = activePoolsV4.data;
      setStatusPools(pools).then(() => {
        setActivePoolsV3Display(pools);
      });
    }
  }, [activePoolsV4, loadingActivePoolsV4]);

  const activePoolHighlight = useMemo(() => {
    if (!activePoolsV3Display || !activePoolsV3Display?.length) return null;
    return activePoolsV3Display[0];
  }, [activePoolsV3Display]);

  const activePoolNormal = useMemo(() => {
    if (!activePoolsV3Display || activePoolsV3Display?.length < 2) return null;
    return activePoolsV3Display?.filter(
      (value) => value?.id !== activePoolsV3Display?.[0]?.id
    );
  }, [activePoolsV3Display]);

  return (
    <>
      <Welcome {...props} />
      <Partners />
      <div className={styles.forUser}>
        <div />
        <p>For Users</p>
      </div>
      <UserProcess />

      {activePoolsV3Display && activePoolsV3Display.length > 0 && (
        <>
          <div className={styles.currentUpcoming}>
            <div>
              <h6>Current Launches</h6>
            </div>
          </div>
          <div className={styles.listPools}>
            <div className={styles.cardSpace}>
              {activePoolHighlight &&
                (isTablet ? (
                  <CardActive pool={activePoolHighlight} />
                ) : (
                  <Card
                    pool={activePoolHighlight}
                    autoFetch={true}
                    refetchData={refetchData}
                  />
                ))}
            </div>
            {!!activePoolNormal?.length && (
              <div className="pools">
                {activePoolNormal?.map((pool: any, index) => {
                  return (
                    <Card
                      pool={pool}
                      key={pool.id}
                      autoFetch={true}
                      refetchData={refetchData}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
      {upcomingPoolsV3Display && upcomingPoolsV3Display.length > 0 && (
        <>
          <div className={styles.currentUpcoming}>
            <div>
              <h6>Upcoming Launches</h6>
            </div>
          </div>
          <div className={styles.listPools}>
            <div className="pools">
              {upcomingPoolsV3Display?.map((pool: any, index) => {
                return (
                  <Card
                    pool={pool}
                    key={pool.id}
                    autoFetch={true}
                    isUpcoming={true}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
      <p className={styles.textCurrentUpcoming}>
        Gain early access to public and special token sales before they hit the
        market.
      </p>

      <div className={styles.joinNow}>
        <Link to="/pools">
          <button className={styles.buttonJoinNow}>
            Join Now{" "}
            <img
              src="/images/landing/arrow_right.svg"
              width={18}
              height={8}
              alt="arrow"
            />
          </button>
        </Link>
      </div>
      <Staking />
      <TierSystem />
      <div className={styles.forUser}>
        <div />
        <p>For Issuers</p>
      </div>
      <StreamLined />
      <LaunchWithUs />

      {/* <ApplyToLaunch /> */}
    </>
  );
};

export default withWidth()(withRouter(Dashboard));

import useStyles from "./style";

const LaunchWithUs = () => {
  const styles = useStyles();

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.borderGradient}>
          <div className={styles.contentBorderGradient}>
            <h2>Why Launch With Us?</h2>
            <div className={styles.launchContent}>
              <div className={styles.launchContentChild}>
                <img
                  src="/images/landing/launch_us.svg"
                  width={53}
                  height={52}
                  alt="raisse"
                />
                <div>
                  <h6>Tap Into Web3</h6>
                  <p>
                    Leverage decentralized technology to create more liquidity
                    for your real world assets.
                  </p>
                </div>
              </div>
              <div className={styles.launchContentChild}>
                <img
                  src="/images/landing/launch_us.svg"
                  width={53}
                  height={52}
                  alt="raisse"
                />
                <div>
                  <h6>Leverage the RWA Network</h6>
                  <p>
                    Gain access to a broad ecosystem of investors and partners
                    to support you along your journey.
                  </p>
                </div>
              </div>
              <div className={styles.launchContentChild}>
                <img
                  src="/images/landing/launch_us.svg"
                  width={53}
                  height={52}
                  alt="raisse"
                />
                <div>
                  <h6>Raise Funds Publicly</h6>
                  <p>
                    Access interested parties on a global scale in a transparent
                    and decentralized setting.
                  </p>
                </div>
              </div>
            </div>
            <a
              className={styles.applyBtn}
              href="https://www.rwa.inc/apply"
              target="_blank"
              rel="noreferrer"
            >
              <button>
                Apply for launchpad
                <img
                  src="/images/landing/arrow_right.svg"
                  width={18}
                  height={8}
                  alt="arrow"
                />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaunchWithUs;

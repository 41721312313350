import { useHistory } from "react-router";
import useStyles from "./style";

const streamLine = [
  {
    title: "01 Application",
    img: "/images/dashboard/application_1.svg",
    des: "Projects apply on the RWA Governance Portal.",
  },
  {
    title: "02 Due Diligence",
    img: "/images/dashboard/application_2.svg",
    des: "The RWA research team reviews applications through a rigorous due diligence process",
  },
  {
    title: "03 The RWA Council",
    img: "/images/dashboard/application_3.svg",
    des: "If the internal due diligence team passes the project, it then goes to a community vote where token holders vote to approve or deny projects for launch",
  },
  {
    title: "04 Pre-Launch Support",
    img: "/images/dashboard/application_4.svg",
    des: "Once approved, projects get full RWA team support leading up to the sale.",
  },
  {
    title: "05 IDO",
    img: "/images/dashboard/application_5.svg",
    des: "After the project details are fully prepared and vetted, the token goes live for launch on the RWA Launchpad.",
  },
];

const StreamLined = () => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <div className={styles.main}>
      <h2>Streamlined Application and Launch Process</h2>
      <div className={styles.streamLine}>
        {streamLine?.map((value, index) => {
          return (
            <div key={value?.title} className={styles.streamLineItem}>
              <img src={value?.img} alt="img" />
              <h4>{value?.title}</h4>
              <p>{value?.des}</p>
              {!index && (
                <button onClick={() => history.push("/pools")}>
                  Apply
                  <img
                    src="/images/landing/arrow_apply.svg"
                    width={7}
                    height={12}
                    alt="arrow"
                  />
                </button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StreamLined;

import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    main: {
      maxWidth: 1320,
      margin: "0 auto",
      padding: "120px 20px 0",
    },
    title: {
      alignItems: "center",
      justifyContent: "space-between",
      "& h2": {
        fontSize: 48,
        lineHeight: "48px",
        fontWeight: 500,
        color: "#0055D6",
        whiteSpace: "nowrap",
      },
      "& p": {
        fontSize: "20px",
        leading: "24px",
        color: "#000B60",
      },
      [theme.breakpoints.down("md")]: {
        "& h2": {
          fontSize: 30,
          lineHeight: "30px",
          fontWeight: 600,
        },
      },
      [theme.breakpoints.down("xs")]: {
        display: "grid",
        textAlign: "center",
        justifyContent: "center",
      },
    },
    step: {
      display: "grid",
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
      paddingTop: 44,
      gap: 20,
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
      },
    },
    stepItem: {
      padding: "56px 51px",
      border: "1px solid #A5CCFF",
      borderRadius: "20px",
      background: "linear-gradient(91.5deg, #ECF5FF 1.28%, #FFFFFF 135.26%)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "start",
      "& h6": {
        paddingTop: "12px",
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#0055D6",
      },
      "& p": {
        paddingTop: "4px",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "19px",
        color: "#000000",
      },
      "& button": {
        maxHeight: "44px",
        width: "100%",
        marginTop: "20px",
        padding: "15px 25px",
        background: "#0058FF",
        borderRadius: "45px",
        border: "none",
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: 600,
        color: "#FFFFFF",
        "& div": {
          paddingBottom: "4px",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      [theme.breakpoints.down("md")]: {
        padding: "20px",
        "& h6": {
          paddingTop: "8px",
          fontSize: "20px",
          lineHeight: "20px",
        },
        "& button": {
          marginTop: "20px",
          padding: "8px 20px",
          "& div": {
            paddingBottom: "4px",
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& button": {
          maxWidth: "256px",
          height: "44px",
        },
        "& div": {
          display: "flex",
          gap: "6px",
          alignsItems: "center",
          justifyContent: "center",
        },
        "& h6": {
          padding: "3px 0 0",
        },
      },
    },
    stepItemDiv: {
      display: "flex",
      flexDirection: "column",
    },
  };
});

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    swapProgress: {
      display: "flex",
      flexDirection: "column",
      color: "#fff",
      paddingBottom: 16,

      "& .title-progress": {
        fontSize: "24px",
        lineHeight: "24px",
        fontWeight: 600,
        color: "#000B60",
      },
      "&. text-progress": {
        font: "12px/16px ",
      },
    },
    titleProgressArea: {
      font: "14px/24px ",
      color: "#AEAEAE",
    },
    progress: {
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      marginTop: 14,
      marginBottom: 6,
    },

    progressBar: {
      marginLeft: "auto",
      display: "block",
      width: "100%",
      height: 4,
      background: "rgba(129, 168, 249, 0.2)",
      // boxShadow: '0px 4px 8px rgba(208, 31, 54, 0.4)',
      borderRadius: 20,
      maxWidth: 240,
      [theme.breakpoints.down("xs")]: {
        width: "calc(100%)",
        maxWidth: "calc(100%)",
      },
    },

    iconCurrentProgress: {
      width: 16,
      height: 16,
      borderRadius: 16,
      background: "#B073FF",
      position: "absolute",
      top: -5,
      right: -10,
    },

    currentProgress: {
      position: "relative",
      height: 4,
      background: "#1F5CD0",
      display: "block",
      transition: "2s",

      "&.inactive": {
        width: "0 !important",
      },
    },

    progressInfo: {
      display: "flex",
      flexDirection: "row",
      font: "12px/16px ",
      color: "#fff",
      alignItems: "center",

      "& span": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "&:first-child": {
          padding: "4px 12px 6px",
          background: "#0055D6",
          borderRadius: 20,
          fontSize: "12px",
          lineHeight: "14px",
        },
        "&:last-child": {
          marginLeft: "auto",
          fontSize: "14px",
          lineHeight: "16px",
          color: "#000B60",
        },
      },
    },

    notification: {
      // marginBottom: 12,
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",

      "& .noti-whitelist": {
        display: "flex",
        fontSize: "24px",
        lineHeight: "24px",
        fontWeight: 600,
        textTransform: "capitalize",
        justifyContent: "space-between",
        "& img": {
          marginRight: "4px",
          width: "14px",
        },
      },

      "& .noti-allocation": {
        // flex: 1,
        color: "#000B60",
        fontSize: 16,
        lineHeight: "19px",
        marginTop: 4,
        marginBottom: 8,
      },

      "& .text-amount": {
        font: "normal normal bold 12px/16px ",
      },
      "& .not-win": {
        color: "#DC3030",
      },
      "& .win": {
        color: "#00A82F",
      },
      "& .normal": {
        color: "#000B60",
      },
      "& .applied": {
        color: "#6A00F0",
      },
    },
    upperText: {
      textTransform: "uppercase",
    },

    alertVerifyEmail: {
      marginTop: -25,
      marginBottom: 15,
      position: "relative",
      width: "100%",
      padding: 9,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      background: "#591425",
      borderRadius: "8px",
      minHeight: 42,

      "& .btn-close": {
        position: "absolute",
        top: "50%",
        right: "15px",
        transform: "translateY(-50%)",
      },

      "& span": {
        fontFamily: "",
        fontSize: 14,
        lineHeight: "20px",
        color: "#FFFFFF",
      },

      "& a": {
        color: "#6398FF",
      },

      [theme?.breakpoints?.down("sm")]: {
        alignItems: "flex-start",
      },
    },

    errroTier: {
      width: "100%",
      display: "flex",
      padding: 12,
      position: "relative",
      background: "#591425",
      alignItems: "center",
      borderRadius: 8,
      marginBottom: 25,
      flexDirection: "row",
      justifyContent: "center",
      fontFamily: "",
      fontSize: 16,
      lineHeight: "24px",
      color: "#FFFFFF",

      [theme?.breakpoints?.down("sm")]: {
        alignItems: "flex-start",
      },
    },

    warningWhite: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      padding: 10,
      flexDirection: "row",
      justifyContent: "center",
      marginBottom: 25,
      background: "#244A9C",
      borderRadius: 8,
      fontFamily: "",
      fontSize: 14,
      lineHeight: "20px",
      color: "#FFFFFF",
      minHeight: 42,

      [theme?.breakpoints?.down("sm")]: {
        alignItems: "flex-start",
      },
    },

    whitelistPending: {
      width: "100%",
      display: "flex",
      padding: 12,
      flexDirection: "row",
      paddingLeft: "2rem",
      marginBottom: 25,
      background: "#D0AA4D",
      borderRadius: 8,
      fontFamily: "",
      fontSize: 16,
      lineHeight: "24px",
      color: "#070A1B",
      "& svg": {
        marginRight: ".5rem",
        minWidth: "20px",
      },

      [theme?.breakpoints?.down("sm")]: {
        alignItems: "flex-start",
        paddingLeft: "1rem",
      },
    },

    whitelistSuccess: {
      width: "100%",
      display: "flex",
      padding: 12,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: "2rem",
      marginBottom: 25,
      background: "#244A9C",
      borderRadius: 8,
      fontFamily: "",
      fontSize: 16,
      lineHeight: "24px",
      color: "#FFFFFF",
      "& svg": {
        marginRight: ".5rem",
        minWidth: "20px",
      },

      [theme?.breakpoints?.down("sm")]: {
        alignItems: "flex-start",
        paddingLeft: "1rem",
      },
    },

    headerComponent: {
      color: "#FFFFFF",
      marginBottom: 16,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    flexCol: {
      display: "flex",
      flexDirection: "column",
    },
    flexRow: {
      display: "flex",
    },
    buyTokenHeader: {
      marginBottom: 18,
      color: "#FFFFFF",
      display: "flex",

      "& .token-symbol": {
        font: "14px/24px ",
        color: "#AEAEAE",
        marginTop: 4,
      },

      "&.is_address": {
        flexDirection: "column",
        marginBottom: 8,
      },

      [theme?.breakpoints?.down("sm")]: {
        padding: "0 28px",
      },

      [theme?.breakpoints?.down("sm")]: {
        display: "grid",
        marginBottom: 24,
      },
    },
    tokenImg: {
      width: 60,
      height: 60,
      borderRadius: 12,
      marginRight: 12,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme?.breakpoints?.down("sm")]: {
        marginRight: 0,
      },
    },
    iconToken: {
      width: "100%",
      height: "100%",
      borderRadius: 12,
      objectFit: "cover",
    },
    title: {
      fontSize: "32px",
      lineHeight: "32px",
      fontWeight: 600,
      color: "#0058FF",
    },
    headerWrap: {
      display: "flex",
      width: "100%",
      "& .token-symbol": {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#000B60",
      },

      [theme?.breakpoints?.down("xs")]: {
        width: "auto",
        flex: 1,
        flexDirection: "column",
      },
    },
    poolHeaderInfo: {
      width: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      marginLeft: "auto",

      [theme?.breakpoints?.down("xs")]: {
        alignItems: "flex-start",
        marginLeft: 0,
        marginTop: 8,
      },
    },
    labelStatus: {
      padding: "8px 20px 10px",
      background: "#D5E3FF",
      color: "#000B60",
      borderRadius: 60,
      marginLeft: 6,
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: 600,
      "&:first-child": {
        marginLeft: 0,
      },

      [theme?.breakpoints?.down("xs")]: {
        padding: "3px 10px",
      },
    },
    socialIcons: {
      marginTop: 8,
      display: "flex",

      [theme?.breakpoints?.down("xs")]: {
        marginTop: 12,
      },
    },
    itemSocsial: {
      width: 24,
      height: 24,
      borderRadius: "50%",
      marginRight: 8,
      textAlign: "right",
      cursor: "pointer",

      "&:last-child": {
        marginRight: 0,
      },
      "& img": {
        height: 24,
        width: 24,
      },
      "&:hover": {
        opacity: 0.8,
      },
    },

    address: {
      display: "flex",
      alignItems: "center",
      fontFamily: "",
      fontSize: 14,
      lineHeight: "18px",
      marginBottom: 20,

      "& a": {
        color: "#6398FF",
        textDecoration: "underline",
      },
      "& img": {
        marginLeft: 6,
      },
    },

    navHeaderComponent: {
      display: "flex",
      flexWrap: "wrap",
      alignContent: "center",
      alignItems: "center",
      // justifyContent: 'center',
      wordBreak: "break-word",
      marginLeft: "-7px",

      [theme?.breakpoints?.down("sm")]: {
        display: "grid",
        gridTemplateColumns: "50% 50%",
        width: "100%",
        gridRowGap: 14,
      },
    },

    item: {
      display: "flex",
      alignItems: "center",
      fontFamily: "",
      fontWeight: "bold",
      fontSize: 14,
      lineHeight: "18px",
      padding: "0 12px",
      borderRight: "1px solid #44454B",

      "&:last-child": {
        borderRight: 0,
        paddingRight: 0,
      },

      [theme?.breakpoints?.down("sm")]: {
        borderRight: 0,
        fontSize: 14,
        lineHeight: "18px",
        fontWeight: "bold",
        padding: "0 8px",
      },
    },

    iconItem: {
      height: 20,
      marginRight: 4,

      [theme?.breakpoints?.down("sm")]: {
        height: 16,
      },
    },

    applyWhiteListButton: {
      width: 200,
      height: 42,
      background: "#D01F36",
      borderRadius: 60,
      color: "white",
      border: "none",
      marginTop: 32,
    },
    solanaWallet: {
      color: "white",
      display: "flex",
      font: "normal normal 500 12px/16px ",

      "& span": {
        color: "#D01F36",
      },

      "& div": {
        marginLeft: 30,
        fontSize: 12,
        lineHeight: "16px",
        color: "#D01F36",
        textDecoration: "underline",
        cursor: "pointer",

        "&:hover": {
          opacity: 0.8,
        },
      },
    },
    solanaInput: {
      display: "flex",
      color: "white",
      maxWidth: 317,
      marginTop: 4,
      padding: "8px 12px",
      background: "linear-gradient(180deg, #252525 0%, #191919 100%)",
      border: "1px solid #44454B",
      borderRadius: 4,

      "& span": {
        marginLeft: 6,
        font: "normal normal 500 12px/16px ",
      },
    },
  };
});

export default useStyles;

import React from "react";
import useStyles from "../style";
//@ts-ignore
import { Fade } from "react-reveal";
import StakingLanding from "../StakingLanding";
const nextIcon = "/images/icons/next.svg";

const Staking = () => {
  const styles = useStyles();
  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardStakeTitle}>
        <div className={styles.cardTitle}>Staking Pools</div>
        <div className={styles.cardSubTitle}>
          Earn yield, boost your multiplier, and unlock access to higher tiers
          by staking in the $RWA pools.
        </div>
      </div>

      <StakingLanding />
      <div className={styles.btnStakeDiv}>
        <a href="/staking-pools">
          <button className={styles.btnStakeNow}>
            Stake Now{" "}
            <img
              src="/images/landing/arrow_right.svg"
              width={18}
              height={8}
              alt="arrow"
            />
          </button>
        </a>
      </div>
    </div>
  );
};

export default Staking;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    main: {
      maxWidth: 1320,
      margin: "0 auto",
      padding: "120px 20px 0",
    },
    title: {
      gap: 16,
      alignItems: "center",
      justifyContent: "space-between",
      "& h2": {
        fontSize: 48,
        lineHeight: "48px",
        fontWeight: 500,
        color: "#0055D6",
        whiteSpace: "nowrap",
      },
      "& p": {
        maxWidth: 630,
        marginTop: 4,
        fontSize: "20px",
        leading: "24px",
        color: "#000B60",
        "& a": {
          color: "#0058FF",
          textDecoration: "underline",
        },
      },
      [theme.breakpoints.down("md")]: {
        "& h2": {
          fontSize: 30,
          lineHeight: "30px",
          fontWeight: 600,
        },
      },
      [theme.breakpoints.down("sm")]: {
        display: "grid",
        textAlign: "center",
        justifyContent: "center",
        "& p": {
          maxWidth: "none",
          textAlign: "center",
        },
      },
      [theme.breakpoints.down("xs")]: {
        display: "grid",
        textAlign: "center",
        justifyContent: "center",
        "& p": {
          maxWidth: "none",
          textAlign: "center",
        },
      },
    },
    tierTable: {
      paddingTop: 32,
    },
  };
});

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    container: {
      position: "relative",
      background: "#ffffff",
      "& img": {
        width: "100%",
        height: "auto",
      },
      "& > div": {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
        width: "100%",
      },
    },
    mainEvent: {
      margin: "80px max(100px, 50% - 480px)",
      "& img": {
        width: "auto",
        [theme.breakpoints.down("md")]: {
          maxWidth: "calc(100% - 360px)",
        },
      },
    },
    cardSpace: {
      paddingBottom: "33px",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "60px",
      },
    },
    forUser: {
      position: "relative",
      display: "flex",
      maxWidth: "1280px",
      height: "68px",
      margin: "120px auto 0",
      paddingBottom: "10px",
      alignItems: "end",
      "& div": {
        width: "100%",
        height: 1,
        background: "#7FB6FF",
      },
      "& p": {
        position: "absolute",
        bottom: "0",
        left: "50%",
        padding: "0 40px",
        transform: "translate(-50%, 0)",
        fontSize: "68px",
        lineHeight: "68px",
        color: "#0055D6",
        fontWeight: 600,
        background: "#FFFFFF",
      },
      [theme.breakpoints.down("md")]: {
        height: "50px",
        padding: "0 20px 10px",
        "& p": {
          fontSize: "50px",
          lineHeight: "50px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        height: "40px",
        margin: "50px auto 0",
        padding: "0 20px 5px",
        "& p": {
          fontSize: "40px",
          lineHeight: "40px",
        },
      },
    },
    currentUpcoming: {
      padding: "120px 20px 0",
      background: "#ffffff",
      textAlign: "center",
      "& div": {
        gap: "40px",
        maxWidth: "1280px",
        margin: "0 auto",
        "& h6": {
          fontSize: "48px",
          lineHeight: "48px",
          fontWeight: 500,
          color: "#0055D6",
        },
      },
      [theme.breakpoints.down("sm")]: {
        padding: "50px 20px 0",
      },
    },
    textCurrentUpcoming: {
      maxWidth: "1320px",
      margin: "0 auto",
      padding: "40px 20px 0",
      fontSize: "20px",
      lineHeight: "24px",
      color: "#000B60",
      textAlign: "center",
    },
    btnViewAllPools: {
      border: "none",
      outline: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingRight: 12,
      marginLeft: "auto",
      cursor: "pointer",
      color: "#fff",
      background: "inherit",
      font: "normal normal 500 16px/24px ",
      "& img": {
        width: 10,
      },
      "& div": {
        width: 24,
        height: 24,
        background: "transparent",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginLeft: 8,
      },
    },

    listPoolsHeader: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
    },

    pagination: {
      display: "flex",
      justifyContent: "center",
      marginTop: "18px !important",
      "& *": {
        color: "white",
      },
      "& .MuiPaginationItem-page.Mui-disabled": {
        cursor: "not-allowed",
        pointerEvents: "auto",
      },
      "& .MuiPagination-ul": {
        flexWrap: "nowrap !important",
      },
      "& li": {
        "& .pagination-item": {
          font: "normal normal 500 14px/18px ",
          backgroundColor: "transparent !important",
          color: "#EFEFE596",
          "&.Mui-selected": {
            color: "#fff",
          },
        },
        "&:first-child .pagination-item": {
          backgroundColor: "#2E2E2E !important",
          borderRadius: 2,
          color: "#FFFFFF",
        },
        "&:last-child .pagination-item": {
          backgroundColor: "#2E2E2E !important",
          borderRadius: 2,
          color: "#FFFFFF",
        },
      },
    },
    joinNow: {
      display: "flex",
      paddingTop: "12px",
      justifyContent: "center",
    },
    buttonJoinNow: {
      padding: "16px 48px 18px",
      background: "#0058FF",
      border: "none",
      borderRadius: "45px",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#FFFFFF",
    },

    listPools: {
      width: "100%",
      margin: "auto",
      maxWidth: "1320px",
      marginTop: 34,
      padding: "0 20px",
      position: "relative",
      "&.live-pools": {
        marginTop: 0,
      },

      "&.listPools2": {
        marginTop: 40,
        // maxWidth: "calc(100vw - 80px)",
      },
      "&.events": {
        marginBottom: 120,
        [theme.breakpoints.down("sm")]: {
          marginBottom: 60,
        },
      },

      "& h2": {
        font: "normal normal 500 20px/24px ",
        color: "#FFFFFF",
        marginBottom: 16,
      },

      "& h3": {
        font: "normal normal 500 16px/24px ",
        marginBottom: 16,
        color: "#FFFFFF",
      },

      "& .pools": {
        display: "Grid",
        gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        gap: 20,
        margin: "auto",
        placeContent: "center",

        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down("xs")]: {
          gridTemplateColumns: "1fr",
        },
      },

      "& .active_pools": {
        display: "Grid",
        gridTemplateColumns: "1fr",
        gap: 20,
        margin: "auto",
        placeContent: "center",

        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
          // padding: 20,
        },
      },
      "& .btn": {
        height: "42px",
        fontFamily: "",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#FFFFFF",
        border: "none",
        outline: "none",
        padding: "0 27px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "60px",
        backgroundColor: "#D01F36",
        margin: "40px auto 0",
        cursor: "pointer",
      },
    },
    skeleton: {
      background: "#515151",
      borderRadius: 4,
    },
    skeletonCard: {
      width: "100%",
      height: 298,
      borderRadius: 12,
      background: "#2E2E2E",
    },
    skeletonCardBottom: {
      height: 138,
      padding: "16px 20px",
      display: "flex",
      flexDirection: "column",
    },
    skeletonCardActive: {
      width: "100%",
      height: 300,
      padding: 32,
      paddingRight: 7,
      borderRadius: 12,
      background: "#2E2E2E",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      [theme.breakpoints.only("xs")]: {
        gridTemplateColumns: "1fr",
        height: 388,
        padding: 12,
        paddingBottom: 20,
      },
    },
    skeletonCardActiveRight: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 460,
      padding: "8px 54px 0 40px",
      [theme.breakpoints.only("xs")]: {
        padding: "20px 0",
      },
    },
    skeletonCompletedSalesItem: {
      width: "100%",
      height: 84,
      borderRadius: 12,
      background: "#2E2E2E",
      marginTop: 12,
      display: "grid",
      padding: "16px 20px",
      gridTemplateColumns: "1fr 1fr 1fr",
      gap: 20,
    },
    skeletonCardActiveImg: {
      width: 460,
      height: 235,
      [theme.breakpoints.only("xs")]: {
        width: "100%",
        height: 150,
      },
    },
    skeletonPoolInfo: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gap: 20,
      height: 48,
      [theme.breakpoints.only("xs")]: {
        height: 36,
      },
    },
    skeletonCompletedSalesMobile: {
      width: "100%",
      height: 180,
      background: "#2E2E2E",
      borderRadius: 12,
      marginTop: 12,
      padding: "16px 20px 12px",
      display: "flex",
      flexDirection: "column",
    },
    boxSkeletonCompletedSales: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 40,
      marginTop: 8,
    },
    subItemCompletedSales: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 20,
    },
    flexRow: {
      display: "flex",
    },
    flexCol: {
      display: "flex",
      flexDirection: "column",
    },
    pullRight: {
      marginLeft: "auto",
    },
    mt4: {
      marginTop: 4,
    },
    mt12: {
      marginTop: 12,
    },

    getAlert: {
      marginTop: 40,
      position: "relative",
      backgroundImage: "url(/images/bg_launch.png)",
      // backgroundSize: 'cover',
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },

    contentGetAlert: {
      width: 1080,
      margin: "auto",
      maxWidth: "calc(100vw - 80px)",
      minHeight: 224,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
    },

    titleGetAlert: {
      font: "normal normal bold 32px/36px ",
      textAlign: "center",
      marginBottom: 12,
    },

    desGetAlert: {
      fontFamily: "",
      fontSize: 16,
      lineHeight: "24px",
      marginBottom: 40,
    },

    btnGetAlert: {
      minWidth: 170,
      minHeight: 36,
      maxWidth: "100%",
      background: "#000000",
      borderRadius: 4,
      border: "none",
      font: "normal normal bold 14px/18px ",
      color: "#FFFFFF",
      cursor: "pointer",
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      // [theme.breakpoints.down('sm')]: {
      //   width: 280,
      // },
      "&:hover": {
        opacity: 0.8,
      },
      "&:focus": {
        outline: "none",
      },

      "& img": {
        width: 12,
        marginRight: 6,
      },
    },

    tokenSaleTitle: {
      display: "inline-block",
      margin: "40px max(120px, 50% - 480px)",
      color: "white",
      position: "relative",
      "& h1": {
        color: "#EFEFE5",
        font: "normal normal 400 40px/48px ",
      },
      "& p": {
        color: "rgba(239, 239, 229, 0.8)",
        font: "normal normal 400 16px/24px ",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "0 20px",
        "& h1": {
          color: "#EFEFE5",
          font: "normal normal 400 32px/40px ",
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      currentUpcoming: {
        "& div": {
          gap: "12px",
          "& h6": {
            fontSize: "30px",
            lineHeight: "30px",
            fontWeight: 600,
            color: "#0055D6",
            textAlign: "center",
          },
        },
      },
      textCurrentUpcoming: {
        fontSize: "20px",
        lineHeight: "24px",
        color: "#000B60",
      },
      joinNow: {
        padding: "20px",
      },
      buttonJoinNow: {
        padding: "10px 20px 12px",
      },
      listPools: {
        marginTop: 20,
        "& .pools": {
          display: "Grid",
          gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
          gap: 10,
          margin: "auto",
          placeContent: "center",

          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr 1fr",
          },
          [theme.breakpoints.down("xs")]: {
            gridTemplateColumns: "1fr",
          },
        },
      },
    },
    // mobile
    [theme.breakpoints.only("xs")]: {
      listPools: {
        marginBottom: 32,
        "& h2": {
          fontSize: 18,
          lineHeight: "26px",
        },
        "& .pools": {
          gap: 60,
        },
      },
      listPoolsHeader: {
        marginBottom: 8,
        "& h2": {
          marginBottom: 0,
        },
      },
      titleGetAlert: {
        fontSize: 28,
        lineHeight: "32px",
      },
      joinNow: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      },
      buttonJoinNow: {
        width: 256,
        height: 44,
      },
    },
  };
});

export default useStyles;
